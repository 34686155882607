import React from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Slider from "react-slick"
import teamgroup from "../images/career-1desk.jpg"
import team_mob from "../images/career-mobile.jpg"

import Remote_Work from "../images/remote-work.svg"
import Competitive_Salary from "../images/competitive_salary.svg"
import Awesome_Food from "../images/food.svg"
import Maternity_Leave from "../images/maternity-leave.svg"
import Games from "../images/games.svg"
import Pet_friendly from "../images/pet-friendly.svg"

import { Helmet } from "react-helmet"
import "animate.css/animate.min.css"
import Captcha from "../components/captcha"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import axios from "axios"
import JobInfoModel from "../components/jobInfoModel"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const reason_slider = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
      },
    },

  ],
}

export default class Career extends React.Component {
  constructor(props) {
    super(props)
    //export  default {code};
    this.state = {
      url: "",
      host: "",
      name: "",
      email: "",
      phone: "",
      job: "",
      response: "",
      displayResult: false,
      displayClass: "",
      code: this.generateCaptchaCode(),
      captcha: "",
      attachment: null,
      attachmentDOM: null,
      inputKey: Date.now(),
      key: Date.now(),
      submit_text: "Submit",
      is_processing: false,
      jobsVacancies: [],
      jobName: "",
      jobDescription: "",
      jobResponsibility: "",
      jobSkills: "",
      jobMsg: "",
    }
    this.jobTitle = React.createRef()
    this.onChange = this.onChange.bind(this)
    this.changeCaptcha = this.changeCaptcha.bind(this)
    this.scrollToComment = this.scrollToComment.bind(this)
    this.myRef = React.createRef();

    const isBrowser = typeof window !== "undefined"
    let SHEET_URL;
    let hostName;
    if (isBrowser) {
      hostName = window.location.host
    }
    if (hostName === 'www.cueforgood.com') {
      SHEET_URL = 'https://script.google.com/macros/s/AKfycbz8111ZSW0xSpGVfTzs4LsC4UTtjvMQgAuIbGN2-Ey5Kw-_SjPViQeamJBh6HztVKnn-A/exec'
    } else {
      SHEET_URL = 'https://script.google.com/macros/s/AKfycbx6cvf3MLEG0wyk6QZOKRrFAp63rnI2gudhhOUhLJtES1UlMN6Ilgd9hxgehcibXzN5/exec'
    }
    axios.get(SHEET_URL)
      .then(res => {
        console.log(res.data)
        this.setState({
          jobsVacancies: res.data
        })
      }).catch(err => console.log(err))

  }

  generateCaptchaCode() {
    let charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*"
    let lengthOtp = 6
    let captcha = []
    for (let i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      let index = Math.floor(Math.random() * charsArray.length + 1) //get the next character from the array
      if (captcha.indexOf(charsArray[index]) === -1)
        captcha.push(charsArray[index])
      else i--
    }
    return captcha.join("")
  }

  componentDidMount() {
    this.setState({
      url: document.location.href,
      host: document.location.host,
    })
    document.addEventListener("keydown", this.keyboardShortcut, false)
    this.scrollToComment();

  }

  scrollToComment() {
    let currentLocation = window.location.href;
    const hasCommentAnchor = currentLocation.includes("/#");

    if (hasCommentAnchor) {
      const anchorCommentId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
      const anchorComment = document.getElementById(anchorCommentId);
      if (anchorComment) {
        console.log(anchorComment);
        window.scrollTo(0, this.myRef.current.offsetTop);
        // anchorComment.scrollTo({ behavior: "smooth" });
        // window.scrollTo(0, anchorComment.current.offsetTop);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyboardShortcut, false)
  }

  keyboardShortcut = event => {
    if (event.keyCode === 27) { //ESC
    }
  }
  handleJobClick = e => {
    e.preventDefault();
    let jobId = e.target.id;
    this.setState({
      showJobModel: true,
      jobName: this.state.jobsVacancies[jobId].jobName,
      jobDescription: this.state.jobsVacancies[jobId].jobDescription,
      jobResponsibility: this.state.jobsVacancies[jobId].jobResponsibility,
      jobSkills: this.state.jobsVacancies[jobId].jobSkills,
      jobMsg: this.state.jobsVacancies[jobId].jobMsg,
    })
  }

  handleApplyJobClick = event => {
    this.setState({
      showJobModel: false,
      job: event.target.getAttribute("data-job"),
    })
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }


  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    if (name === "phone") {
      console.log(name);
      console.log(value);
      let phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$/g
      if (value.trim().length > 0) {
        if (value.trim().length < 10) {
          target.setCustomValidity("Please enter a valid phone number with country code.")
        } else {
          if (!phoneRegex.test(value.trim())) {
            target.setCustomValidity("Please enter a valid phone number with country code.")
          } else {
            target.setCustomValidity("")
          }
        }
      } else {
        target.setCustomValidity("")
      }
    }

    if (name === "email") {
      if (!this.validateEmail(value.trim().toLowerCase())) {
        target.setCustomValidity("Please enter a valid email. email@domain.com")
      } else {
        target.setCustomValidity("")
      }
    }


    this.setState({
      [name]: value,
    })
  }

  onChange(e) {
    this.setState({ attachment: e.target.files[0] })
  }

  onClear() {
    this.setState({
      attachmentDOM: null,
      inputKey: Date.now(),
    })
    if (this.props.onChange) this.props.onChange(null)
  }

  changeCaptcha() {
    this.setState({
      key: Date.now(),
      code: this.generateCaptchaCode(),
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()

    //check captcha
    if (this.state.captcha !== this.state.code) {
      this.setState({
        response: "Captcha not matched",
        displayResult: true,
        displayClass: "error-msg",
      })
      setTimeout(() => {
        this.setState({ displayResult: false })
      }, 3000)
      return
    }

    let HUBSPOT_CONTACT_FORM
    if (this.state.host === "cb-redesign.netlify.com" || this.state.host === "cb-redesign.netlify.app") {
      // HUBSPOT_CONTACT_FORM = 'https://api.hsforms.com/submissions/v3/integration/submit/6881310/7f61437c-71eb-4c0c-b4af-aa31d38dd401';
      HUBSPOT_CONTACT_FORM = "https://apistaging.cueforgood.com/"
    } else if (this.state.host === "localhost:8000") {
      HUBSPOT_CONTACT_FORM = "http://localhost:8001/"
    } else if (this.state.host === "www.cueforgood.com" || this.state.host === "cueforgood.com") {
      HUBSPOT_CONTACT_FORM = "https://apilive.cueforgood.com/"
    } else {
      HUBSPOT_CONTACT_FORM = "https://apistaging.cueforgood.com/"
    }

    // Update the formData object
    formData.append("email", this.state.email)
    formData.append("name", this.state.name)
    formData.append("jobtitle", this.state.job)
    formData.append("mobilephone", this.state.phone)
    formData.append("attachment", this.state.attachment)
    let _this = this
    this.setState({
      submit_text: "Submitting...",
      is_processing: true,
    })
    fetch(HUBSPOT_CONTACT_FORM, {
      method: "post",
      body: formData,
    },
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(function (response) {
          _this.onClear()
          _this.changeCaptcha()
          _this.setState({
            response: response.message,
            services: [],
            budget: 3000,
            name: "",
            email: "",
            phone: "",
            website: "",
            about: "",
            job: "",
            captcha: "",
            displayResult: true,
            displayClass: "success-msg",
            is_processing: false,
            submit_text: "Submitted",
          })


          setTimeout(() => {
            _this.setState({ displayResult: false, submit_text: "Submit" })
          }, 5000)
        })


      } else {
        response.json().then(function (response) {
          _this.setState({
            response: response.message,
            displayResult: true,
            displayClass: "error-msg",
            is_processing: false,
          })
          setTimeout(() => {
            _this.setState({ displayResult: false, submit_text: "Submit" })
          }, 5000)

        })
      }
    }).catch((error) => {
      console.error(error)
      _this.setState({ submit_text: "Submit" })
      _this.changeCaptcha()
    })
  }

  render() {

    return <Layout>
      <Helmet>
        <title>Careers at CueForGood | Join a team focussed on using business as a Force for Good</title>
        <meta name="description"
          content="Want to work with CueForGood? Join forces with us as we propel purpose-driven Ecommerces towards positive ROI and progressive growth. " />
        <link rel="canonical" href={this.state.url} />
        <meta property="og:site_name" content="CueForGood" />
        <meta property="fb:app_id" content="289086684439915" />
        <meta property="og:url" content={this.state.url} />
        <meta property="og:title"
          content="Careers at CueForGood | Join a team focussed on using business as a Force for Good" />
        <meta property="og:description"
          content="Want to work with CueForGood? Join forces with us as we propel purpose-driven Ecommerces towards positive ROI and progressive growth." />
        <meta property="og:image" content="https://www.cueforgood.com/images/services-og.jpg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description"
          content="Want to work with CueForGood? Join forces with us as we propel purpose-driven Ecommerces towards positive ROI and progressive growth." />
        <meta name="twitter:title"
          content="Careers at CueForGood | Join a team focussed on using business as a Force for Good" />
        <meta name="twitter:site" content="@CueForGood" />
        <meta name="twitter:image" content="https://www.cueforgood.com/images/services-og.jpg" />
      </Helmet>
      <section className="career-topsection page-header" >
        <div className="container">
          <div className="ser_sec_row_lt pd_right_40">
            <h2 className="heading_main">Work With Us</h2>
            <div className="ser_sec_row_lt_text ">
              <p>
                With passion as its compass, CueForGood offers a platform with purpose. Our team of developers, designers, and online marketers reimagines eCommerce through creativity
                that outlasts each of us as individuals. We believe in coming to work with ideas that have whiffs of newness and a heart that feels like it is on a trip to La La
                Land.<br /><br />While doing this, we build an environment that allows us to get away from the desks & get our creative juices flowing. If you’re looking for a career that
                fuels your creativity in a company where you are more than just your job, CueForGood would love to hear from you.
              </p>
            </div>
          </div>
          <div className="ser_sec_row_rt pd_left_40">
            {/* <h3 className="heading_main">A Little Sneak-Peek Into CueForGood</h3> */}
            <h3 className="heading_main focus-visible fv-hide" tabIndex={0} id="first-focus-element">A Little Sneak-Peek Into CueForGood</h3>
            <iframe
              title={"Youtube embed"}
              width="100%"
              height="450px"
              className="focus-visible"
              src="https://www.youtube.com/embed/WeHf1HQa7YA?loop=1&playlist=WeHf1HQa7YA"
              frameBorder="0px"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              id="first-focus-element"
            ></iframe>
          </div>
          <div className="border-light"></div>
        </div>
      </section>

      <section className=" our-process reasons-work reason-to-work-wihtus"  >
        <div className="container">
          <div className="main-heading text-center">
            <h2 className="heading_main">We Care - Here’s How</h2>
            <p>Thriving as a place for people and their ideas, we work towards elevating eCommerce businesses (both start-ups and established ones).
              As part of a close-knit team, we create opportunities that help each one of us flourish. But the best thing we do at CueForGood is care for our employees. </p>
          </div>

          <div className="reason-section">
            {/* <h3>We Care - Here’s How </h3> */}
            <div className="reason-slider">
              <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all 1s"
                transitionDuration={1000}
                containerClass="carousel-container"
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                dotListClass="slick-dots"
                itemClass="carousel-item-padding-40-px"
              >
                <div className="reason-inn">
                  <figure><img
                    src={Remote_Work}
                    alt="Remote Work"
                    className="image_responsive"
                  /></figure>
                  <h3>Remote-Friendly Work</h3>
                </div>
                <div className="reason-inn">
                  <figure><img
                    src={Competitive_Salary}
                    alt="Competitive Salary"
                    className="image_responsive"
                  /></figure>
                  <h3>Competitive Salary</h3>
                </div>
                <div className="reason-inn">
                  <figure><img
                    src={Awesome_Food}
                    alt="Awesome Food"
                    className="image_responsive"
                  /></figure>
                  <h3>Wholesome Lunch On-Site</h3>
                </div>
                <div className="reason-inn">
                  <figure><img
                    src={Maternity_Leave}
                    alt="Maternity Leave"
                    className="image_responsive"
                  /></figure>
                  <h3>Maternity/ Paternity Leave</h3>
                </div>
                <div className="reason-inn">
                  <figure><img
                    src={Pet_friendly}
                    alt="Pet-Friendly Workspace"
                    className="image_responsive"
                  /></figure>
                  <h3>Pet-Friendly Workspace</h3>
                </div>
                <div className="reason-inn">
                  <figure><img
                    src={Games}
                    alt="Games, Theme Parties, & More"
                    className="image_responsive"
                  /></figure>
                  <h3>Games, Theme Parties, & More</h3>
                </div>


              </Carousel>
            </div>

          </div>


        </div>
      </section>


      <section className="reason-to-work-wihtus" >
        <div className="container">

          <figure className="team-group">
            <img src={team_mob} alt="CueForGood office image" className="image_responsive desk-hide" />
            <img src={teamgroup} alt="CueForGood office activity" className="image_responsive mob-hide" /> 
          </figure>
          <div className="open-positions">
            <p>
              <span className="highlight-span"><strong>Open Posts</strong></span>
            </p>

            <div className="ser_sec_row_lt ">
              <h2 className="heading_main">
                WE ARE HIRING
                <br />
                AT CueForGood
              </h2>
              <div className="ser_sec_row_rt ">
                <ul>
                  {
                    this.state.jobsVacancies?.map((item, index) => (
                      index < 4 ? (
                        <li key={index}>
                          <button data-job-name="projectcordinator" id={index} onClick={this.handleJobClick}
                            className="magento-job js-open-modal focus-visible" tabindex="0" aria-label={`Job title: ${item.jobName}`}>{item.jobName}</button>
                        </li>
                      ) : null
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="ser_sec_row_rt ">
              <ul>
                {
                  this.state.jobsVacancies?.map((item, index) => (
                    index > 3 ? (
                      <li key={index}>
                        <button data-job-name="projectcordinator" id={index} onClick={this.handleJobClick}
                          className="magento-job js-open-modal focus-visible" tabindex="0" aria-label={`Job title: ${item.jobName}`}>{item.jobName}</button>
                      </li>
                    ) : null
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="" id={"careerform"} ref={this.myRef} style={{ height: "1px" }}></div>
        </div>
      </section>

      <section className="contact-form__block career-form-block" id={"jobTitle"}>
        <div className="container"  >
          <div className="contact-form__row" >
            <h2 className="heading_main">apply now!<br /> <span className="indicate">(<em>*</em> indicates mandatory fields)</span>
            </h2>

            <div className="form__rt" >
              <form onSubmit={this.handleSubmit} encType="multipart/form-data" method={"POST"}>
                <div className="row">
                  <div className="form__row">
                    <label>Name<em>*</em></label>
                    <input type="text" placeholder="Enter your name"
                      value={this.state.name}
                      name="name"
                      required={true}
                      title="Enter a valid name ex. Sarthak aggarwal"
                      onChange={this.handleInputChange}
                      className="focus-visible"
                    />
                    <div className="border-line"></div>
                  </div>
                  <div className="form__row">
                    <label>Email<em>*</em></label>
                    <input type="text"
                      title="hello@domain.com"
                      required={true}
                      placeholder="Enter your email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      className="focus-visible"

                    />
                    <div className="border-line"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="form__row">
                    <label>Phone<em>*</em></label>
                    <input type="text" placeholder="Enter your phone"
                      required={true}
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleInputChange}
                      className="focus-visible"
                    />
                    <div className="border-line"></div>
                  </div>
                  <div className="form__row">
                    <label>Job<em>*</em></label>
                    <input type="text" placeholder="Enter Job title"
                      required={true}
                      name="job"
                      value={this.state.job}
                      onChange={this.handleInputChange}
                      className="focus-visible"
                    />
                    <div className="border-line"></div>
                  </div>
                  <div className="form__row captcha-row" key={this.state.key}>
                    <Captcha {...this.state} /><em>*</em>
                    <button className="chnage-captcha focus-visible" tabIndex={0}
                      onClick={this.changeCaptcha}> Change</button>
                    <input type="text" required
                      name="captcha"
                      id="captcha"
                      autoComplete={"off"}
                      placeholder="Captcha"
                      value={this.state.captcha}
                      onChange={this.handleInputChange}
                      className="focus-visible" />
                  </div>

                  <div className="border-line"></div>

                  <div className="form__row">
                    <label>Attachment(.png, .pdf, .txt, .doc)<em>*</em></label><br />
                    <label>Max size allowed-2 MB</label><br />
                    <input type="file" required
                      name="attachment"
                      id="attachment"
                      key={this.state.inputKey}
                      ref={ref => this.attachmentDOM = ref}
                      placeholder="Attachment"
                      accept={".doc,.docx, application/doc, application/ms-doc, application/msword, text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                      onChange={this.onChange}
                      className="focus-visible" />
                  </div>
                </div>
                <div className="row">
                  <input type="submit" className="main_cta mt0 focus-visible" value={this.state.submit_text}
                    disabled={this.state.is_processing} />
                  {this.state.displayResult ?
                    <div className={this.state.displayClass}
                      style={{ textAlign: "center", marginTop: "10px" }}
                      dangerouslySetInnerHTML={{ __html: this.state.response }}></div> : ""
                  }

                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {
        this.state.showJobModel ? (
          <JobInfoModel
            jobName={this.state.jobName}
            jobDescription={this.state.jobDescription}
            jobResponsibility={this.state.jobResponsibility}
            jobSkills={this.state.jobSkills}
            jobMsg={this.state.jobMsg}
            handleApplyJobClick={this.handleApplyJobClick}
            closeJobModal={() => this.setState({
              showJobModel: false,
            })}
          />
        ) : null
      }
      <Clutch />
    </Layout>
  }
}
