import React, { useEffect, useRef } from "react";

export default function JobInfoModel({ jobName, jobDescription, jobResponsibility, jobSkills, jobMsg, handleApplyJobClick, closeJobModal }) {

    const closeButtonRef = useRef(null);

    useEffect(()=>{
        if (closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    },[])

    return (
        <div className="popup-job">
            <div className="popup-job-inn">
                <h2 className="heading_main" >{jobName}</h2>
                <button className="close-popup js-modal-close close focus-visible" id="closeJobModeBtn" ref={closeButtonRef} tabIndex={0} onClick={closeJobModal}>X</button>
                {
                    jobDescription.split('<br>').map((item, index) => (
                        item !== "" && <p key={index}>{item}</p>
                    ))
                }
                <h4>What will you be doing?</h4>
                <ul>
                    {
                        jobResponsibility.split('<br>').map((item, index) => (
                            item !== "" && <li key={index}>{item}</li>
                        ))
                    }
                </ul>
                <h4>What skills and experience do you need?</h4>
                <ul>
                    {
                        jobSkills.split('<br>').map((item, index) => (
                            item !== "" && <li key={index}>{item}</li>
                        ))
                    }
                </ul>
                <p>{jobMsg.split('<br>').map((item, index) => (
                    item !== "" && <p key={index}>{item}</p>
                ))}</p>
                <a href="#jobTitle" data-job={jobName} onClick={handleApplyJobClick}
                    className="btn main_cta apply-btn">Apply
                    Now</a>
                <h6>Or email us your cv at <a target={'_blank'} href="mailto:careers@cueforgood.com">careers@cueforgood.com</a></h6>
            </div>
        </div>
    );
}
